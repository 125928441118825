import { useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { useBusinessDispatch } from '@safelyq/manage-business';
import { DELETE_APPOINTMENT_QUESTIONS, SAVE_BUSINESS } from './requests';

export const useData = () => {
  const dispatch = useBusinessDispatch();

  const [onSaveBusiness, { loading: isLoading, error }] = useMutation(
    SAVE_BUSINESS,
    {
      onCompleted: ({ saveBusiness: { isSaved } }) => {
        if (isSaved) {
          dispatch({
            type: 'SET_ACCORDION_ACTIVE_STEPS',
            payload: [1, 2, 3, 5, 6, 7, 8],
          });

          showToast.success(
            'Appointment Confirmation Setup saved successfully'
          );
        } else showToast.error('Something went wrong');
      },
      onError: () => showToast.error('Something went wrong'),
    }
  );

  return { isLoading, onSaveBusiness };
};

export const useDeleteAppointmentsQuestionHook = () => {
  const [deleteAppointmentsQuestion, { loading: deleteAppointmentsQuestionLoading, error: deleteAppointmentsQuestionError }] = useMutation(DELETE_APPOINTMENT_QUESTIONS);

  return {
    deleteAppointmentsQuestion,
    deleteAppointmentsQuestionLoading
  }
}