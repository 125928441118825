import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Button,
  Columns,
  Column,
  Buttons,
  Icon,
  Modal,
} from '@safelyq/bulma-ui-library';
import { useForm, useFormState, useFieldArray } from 'react-hook-form';
import { APPOINTMENT_CONFIRMATION_MESSAGES } from '../../common/constants';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';
import { useDeleteAppointmentsQuestionHook } from './hooks';
import { toast } from 'react-toastify';

export const AppointmentConfirmationSetup = ({
  tab,
  data: business,
  onSubmit: onSubmitParent,
  isLoading,
  toolTip,
  refetch
}) => {

  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [deletetingQuestionId, setDeletingQuestionId] = useState(null);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);

  const [error, setError] = useState('');

  const {
    register,
    control,
    handleSubmit: onSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // Watch all form values
  const formValues = watch();

  const [initialState, setInitialState] = useState(null); // appointment questions
  const [initialAppointmentDetails, setInitialAppointmentsDetails] = useState(null); // other details of the appointment
  const { dirtyFields } = useFormState({ control });
  const { deleteAppointmentsQuestion, deleteAppointmentsQuestionLoading } = useDeleteAppointmentsQuestionHook();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });


  useEffect(() => {
    if (!isEmpty(business)) {
      let {
        preCheckinInstructions,
        appointmentConfirmationOffset,
        isAppointmentConfirmationRequired,
        questions,
      } = business;

      questions = questions?.map((question) => ({
        ...question,
        isEditable: false,
        isAllowDelete: false,
      }));

      setInitialState(questions); // setting questions for comparison 

      reset({
        confirmationOffset: String(appointmentConfirmationOffset),
        specialInstructions: preCheckinInstructions[0] || '',
        confirmationIsRequired: isAppointmentConfirmationRequired,
        questions,
      });

      // setting other appointment variables for comparison
      setInitialAppointmentsDetails({
        confirmationOffset: Number(appointmentConfirmationOffset),
        specialInstructions: preCheckinInstructions[0] || '',
        confirmationIsRequired: isAppointmentConfirmationRequired,
      })
    }
  }, [business]);

  const handleQuestionCancel = (index) => {
    remove(index);
    setError(''); // clear error states
  }

  const handleFieldMakeEditable = (index) => {
    const isEditable = fields.find(
      (question, indx) => question.isEditable && index !== indx
    );

    if (isEditable) {
      alert('Please check mark to add changes first');
      return;
    }

    if (!fields[index].isEditable) {
      fields[index].isEditable = true;

      setIsNewQuestion(true);

      reset({ questions: fields });
    } else {
      if (isEmpty(errors)) {
        let questions = watch('questions');

        questions[index].isEditable = false;

        setError('');

        reset({ questions }, { keepDirty: true });
      }
    }
  };

  const handleQuestionConfirmation = (questionId) => {

    setDeletingQuestionId(questionId);
    setShowDeleteQuestionModal(true);
  }

  const handleDeleteQuestion = async () => {
    if (!deletetingQuestionId) return;

    const response = await deleteAppointmentsQuestion({ variables: { businessQuestionId: deletetingQuestionId } });

    const { isSaved, errorMessage } = response?.data?.deleteBusinessQuestion;
    if (isSaved) {
      toast.success("Business appointment confirmation question deleted successfully");
      setShowDeleteQuestionModal(false);
      setDeletingQuestionId(null);
      refetch(); // refetch new questions
    } else if (errorMessage) {
      toast.error(errorMessage || "Could not delete business appointment question")
    }
    setDeletingQuestionId(null)
  };


  // watch the question (get data) and reset the states with the update 
  const handleCancelField = (index) => {
    let questions = [...fields];
    questions[index].isEditable = false;
    setError('');
    reset({ questions }, { keepDirty: true });
  }

  const handleAddMore = (e) => {
    e.preventDefault();

    const isEditable =
      fields.length > 0
        ? fields.filter((question) => question.isEditable).length > 0
        : false;

    if (isEditable) {
      setError('Please check mark to add this question');
      return;
    }

    setIsNewQuestion(false);

    append({
      prompt: '',
      textYes: 'Yes',
      textNo: 'No',
      isEditable: true,
      isAllowDelete: true,
      isActive: false,
    });
  };

  const handleSubmit = (values) => {

    // Clone the array and remove specific properties from each object
    // values has the same data as of fields from useFieldArray as fields data can be used everywhere
    let businessQuestionValues = values?.questions.map(({ isEditable, isAllowDelete, key, ...rest }) => ({
      ...rest
    }));

    fields.forEach(function (v) {
      delete v.isEditable;
      delete v.isAllowDelete;
      delete v.key;
    });

    if (tab === 'LOCATION') {

      let businessQuestionsValues = fields?.filter(
        (question) => Object.keys(question).length !== 0
      );

      let businessQuestions = businessQuestionsValues.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );

      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            businessQuestions,
          },
        },
        8,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    } else {
      let businessQuestions = businessQuestionValues?.filter(
        (question) => Object.keys(question).length !== 0
      );

      businessQuestions = businessQuestions.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );


      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            businessQuestions,
          },
        },
        8,
        business.id,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    }
  };

  const compareArrays = () => {
    if (fields.length !== initialState.length) {
      return false;
    }

    // fields is not going to update unless you remove the editable state by clicking on tick
    const questionsSame = fields.every(field => {
      const matchingQuestion = initialState.find(formQuestion =>
        field.prompt === formQuestion.prompt &&
        field.textNo === formQuestion.textNo &&
        field.textYes === formQuestion.textYes &&
        field.isActive === formQuestion.isActive &&
        field.isUserResponseRequired === formQuestion.isUserResponseRequired
      );

      return !!matchingQuestion; // if the value is null or undefined than it converts it to boolean
    });

    const appointmentDetailsSame =
      initialAppointmentDetails?.confirmationOffset === formValues?.confirmationOffset &&
      initialAppointmentDetails?.confirmationIsRequired === formValues?.confirmationIsRequired &&
      initialAppointmentDetails?.specialInstructions === formValues?.specialInstructions;

    // if both of them are same than return true and disable the button
    if (questionsSame && appointmentDetailsSame) {
      return true;
    }
  };

  const isDataChanged = (formValues, fields, index, initialState, isEditable) => {
    const current = formValues?.questions[index] || {};
    const field = fields[index] || {};

    // form data and field data are not same means data needs to be confirmed
    const isDifferent = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .some(key => current[key] !== field[key]);

    // field length is not same means not saved yet and also in editable state
    return isDifferent && fields.length === initialState.length && isEditable;
  };


  const isDataConfirmed = (formValues, fields, index, initialState, isEditable) => {
    const current = formValues?.questions[index] || {};
    const field = fields?.[index] || {};
    const initial = initialState[index] || {};

    // Field data and form data are same
    const isSame = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .every(key => current[key] === field[key]);

    // Fields data and initial data are not same, means data is updated
    const isUpdated = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .some(key => field[key] !== initial[key]);

    // length is same means data is updated and needed to be saved
    return isSame && isUpdated && fields.length === initialState.length && !isEditable;
  };



  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Columns multiline>
          <Column size='4'>
            <div className='control'>
              <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                Customer can confirm X-mins before appointment
                <Tooltip title={toolTip?.customerCanConfirmX_MinsBeforeAppointment}> <FiInfo /> </Tooltip>
              </label>

              <input
                className='input'
                type='number'
                {...register('confirmationOffset', {
                  min: 0,
                  max: 1440, // max 24 hours
                  valueAsNumber: true
                })}
                onInput={(e) => {
                  const value = e.target.value;
                  // Prevent typing values less than 0 or greater than 1440
                  if (value < 0) e.target.value = 0;
                  if (value > 1440) e.target.value = 1440;
                }}
              />

            </div>
          </Column>
          <Column size='4'>
            <div className='control'>
              <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>Special Instructions
                <Tooltip title={toolTip?.specialInstructions}> <FiInfo /> </Tooltip>
              </label>
              <textarea
                className='textarea'
                rows={3}
                {...register('specialInstructions')}
              />
            </div>
          </Column>
          <Column size='4'>
            <div className='control'>
              <label className='label is-hidden-mobile'>&nbsp;</label>
              <label className='checkbox'>
                <input type='checkbox' {...register('confirmationIsRequired')} />
                <strong className='ml-2'>
                  Appointment Confirmation Allowed ?
                </strong>
                <Tooltip title={toolTip?.appointmentConfirmationAllowed}> <FiInfo /> </Tooltip>
              </label>
            </div>
          </Column>
          <Column size='12'>
            <label className='label'>
              Confirmation Questions <small>({fields?.length} of 10)</small>
            </label>
            <small>
              Maximum 10 questions. Question response can be either yes/no or text.
            </small>
          </Column>
          {fields?.map(({ key, id, isEditable, isUserResponseRequired }, index) => (
            <Fragment>
              <Column size='1'>
                <label className='label'>
                  Visible{' '}

                </label>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    disabled={!isEditable}
                    {...register(`questions.${index}.isActive`)}
                  />

                </label>
              </Column>
              <Column size='4'>
                <div className='control'>
                  <label className='label'>
                    Question {index + 1}{' '}
                    <span className='has-text-danger'>*</span>
                  </label>
                  <div className='is-flex'>
                    <input
                      key={key}
                      disabled={!isEditable}
                      className={`input ${!isEmpty(errors) && errors?.questions[index]?.prompt
                        ? 'is-danger'
                        : ''
                        }`}
                      {...register(`questions.${index}.prompt`, {
                        required: true,
                      })}
                    />
                  </div>
                  {!isEmpty(errors) && errors?.questions[index]?.prompt && (
                    <p className='help is-danger'>Question is required</p>
                  )}
                </div>
              </Column>
              <Column size='4'>
                <label className='label'>
                  Response
                  <span className='has-text-danger'>*</span>
                </label>
                <div>
                  <div className='is-flex'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name={`questions.${index}.isUserResponseRequired`}
                        defaultChecked={!isUserResponseRequired}
                        value={false}
                        onChange={() =>
                          setValue(
                            `questions.${index}.isUserResponseRequired`,
                            false,
                            {
                              shouldDirty: true,
                            }
                          )
                        }
                        disabled={!isEditable}
                      />
                    </label>
                    &nbsp;
                    <div className='control'>
                      <input
                        key={key}
                        disabled={!isEditable}
                        className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textYes
                          ? 'is-danger'
                          : ''
                          }`}
                        {...register(`questions.${index}.textYes`)}
                      />
                    </div>
                    &nbsp;
                    <div className='control'>
                      <input
                        key={key}
                        disabled={!isEditable}
                        className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textNo
                          ? 'is-danger'
                          : ''
                          }`}
                        {...register(`questions.${index}.textNo`)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='is-flex'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name={`questions.${index}.isUserResponseRequired`}
                        defaultChecked={isUserResponseRequired}
                        value={true}
                        onChange={() =>
                          setValue(
                            `questions.${index}.isUserResponseRequired`,
                            true,
                            {
                              shouldDirty: true,
                            }
                          )
                        }
                        disabled={!isEditable}
                      />
                    </label>
                    &nbsp;
                    <div className='control'>
                      <input
                        className='input is-small'
                        disabled={true}
                        placeholder='Text answer required'
                      />
                    </div>
                  </div>
                </div>
              </Column>
              <Column size='2'>
                <label className='label is-hidden-mobile'>&nbsp;</label>
                <Buttons size='small'>
                  {isEditable ? (
                    <Fragment>
                      <div className='is-flex'>
                        <Tooltip
                          title={`Confirm`}>
                          <Button
                            className='mx-3'
                            color='primary'
                            type='button'
                            disabled={formValues?.questions[index]?.prompt.trim() === ''} // if text is not available than dont let user check
                            onClick={() => handleFieldMakeEditable(index)}
                          >
                            <Icon name='check' />
                          </Button>
                        </Tooltip>

                        {/* {!isNewQuestion && ( */}
                        <Tooltip
                          title={`Cancel`}>
                          <Button
                            color='danger'
                            type='button'
                            className='mx-3'
                            onClick={() => !isNewQuestion ? handleQuestionCancel(index) : handleCancelField(index)}
                          >
                            <Icon name='times' />
                          </Button>
                        </Tooltip>
                      </div>
                      {/* )} */}
                    </Fragment>
                  ) : (
                    <div className='is-flex'>
                      <Tooltip
                        title={`Edit this question`}>
                        <Button
                          className='mx-3'
                          color='info'
                          type='button'
                          onClick={() => handleFieldMakeEditable(index)}
                        >
                          <Icon name='pencil' />
                        </Button>
                      </Tooltip>

                      {
                        // show the button if the question is saved
                        id &&
                        <Tooltip
                          title={`Delete this question`}>
                          <Button
                            className='mx-3'
                            color='danger'
                            type='button'
                            loading={deletetingQuestionId === id && deleteAppointmentsQuestionLoading}
                            onClick={() => handleQuestionConfirmation(id)}
                          >
                            <Icon name='trash' />
                          </Button>
                        </Tooltip>
                      }

                    </div>

                  )}

                  {
                    isDataChanged(formValues, fields, index, initialState, isEditable) && (
                      <p className='has-text-danger' style={{ width: "100%" }}>Confirm?</p>
                    )
                  }
                  {
                    isDataConfirmed(formValues, fields, index, initialState, isEditable) && (
                      <p className='has-text-success' style={{ width: "100%" }}>Confirmed</p>
                    )
                  }

                </Buttons>
              </Column>
              <Column size='1' />
            </Fragment>
          ))}
          {error && (
            <Column size='12'>
              <span className='has-text-danger'>{error}</span>
            </Column>
          )}
          <Column size='12'>
            <Link to='#' className='has-text-info' onClick={handleAddMore}>
              <Icon name='plus' className='mr-2' />
              Add Question
            </Link>
          </Column>
          <Column size='12'>
            <Button
              type='submit'
              color='info'
              disabled={isEmpty(dirtyFields) || compareArrays() || isLoading || fields.some(obj => obj.isEditable === true)} // is any field is enabled than dont let save
              loading={isLoading}
            >
              Save
            </Button>
          </Column>
        </Columns>
      </form>

      <Modal active={showDeleteQuestionModal}>
        <Modal.Background />
        <Modal.Card className="responsive-moda">
          <Modal.Card.Body>
            <p className='is-size-5 has-text-weight-bold mb-2'>Delete Appointment Confirmation Question</p>
            <p className='is-size-6'>
              Are you sure you want to delete the question "<span className='has-text-grey-dark'> {fields?.find(question => question?.id === deletetingQuestionId)?.prompt}" ?</span>
            </p>

            <div className='is-flex is-justify-content-end my-3 mt-5'>
              <Button color='info' className='mx-2' onClick={() => setShowDeleteQuestionModal(false)}>Cancel</Button>
              <Button color='danger' className='mx-2' onClick={() => handleDeleteQuestion()}>Delete</Button>
            </div>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </>
  );
};
