import { gql } from '@apollo/client';

export const SAVE_BUSINESS = gql`
  mutation SaveBusiness($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      isSaved
      errorMessage
    }
  }
`;

export const DELETE_APPOINTMENT_QUESTIONS = gql`
mutation DeleteBusinessQuestion($businessQuestionId: Int!) {
	deleteBusinessQuestion(businessQuestionId: $businessQuestionId) {
		errorMessage
		isSaved 
  }
}
`