import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
    Section,
    Container,
    Title,
    Columns,
    Column,
    Field,
    Icon,
    Control,
    TextInput,
} from "@safelyq/bulma-ui-library";
import { Loader } from "@safelyq/base-feature";
import { NoSearchFound, DeleteTableView } from "@safelyq/business-admin/src/components";
import { useData } from "@safelyq/business-admin/src/routes/ViewHome/hooks";
import BreadCrumbs from "features/business-admin/src/components/Breadcrumbs";
import { CgSearchFound } from "react-icons/cg";

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const ManageBusiness = () => {

    const [isRefetching, setIsRefetching] = useState(false);
    const [fetchOneTimeOnly, setFetchOneTimeOnly] = useState(true);
    const [businessState, setBusinessState] = useState(null);
    const [businessStateInitial, setBusinessStateInitial] = useState(null);
    const [matchState, setMatchState] = useState(null); // State to track matches

    const { isLoading, error, data: businesses, refetch } = useData();

    const isEmptyBusinesses = isEmpty(businesses);

    // Filter products by search
    const handleSearch = ({ target: { value } }) => {
        const locations = businessStateInitial.filter(({ name, id, smsPhone, whatsAppPhone }) => {
            if (String(name).toLowerCase().includes(value.toLowerCase())) {
                setMatchState("name");
                return true;
            }
            if (typeof id === "number" && id === parseInt(value)) {
                setMatchState("id");
                return true;
            }
            if (String(smsPhone).includes(value)) {
                setMatchState("smsPhone");
                return true;
            }
            if (String(whatsAppPhone).includes(value)) {
                setMatchState("whatsAppPhone");
                return true;
            }
            return false;
        });
        if (!value || locations?.length === 0) {
            setMatchState(null);
        }
        setBusinessState(locations);
    };

    // Stop custom loading if data is fetched
    useEffect(() => {
        if (fetchOneTimeOnly && businesses?.length !== 0) {
            setIsRefetching(false);
            setBusinessState(businesses)
            setBusinessStateInitial(businesses) // we keep initial state to filter from
            setFetchOneTimeOnly(false)
        }
    }, [businesses])

    // Call api for the first time only
    useEffect(() => {
        refetch();
    }, [])

    if (isLoading || isRefetching) return <Loader isFullScreen />;

    if (error) return <small>Error</small>;

    return (
        <Section>
            <Container className="mb-3">
                <BreadCrumbs />
            </Container>

            <Container>
                <header className="is-flex is-justify-content-space-between is-align-items-center">
                    <Title size="5" className="m-0">
                        Manage Businesses
                    </Title>
                </header>
                <Columns className="m-0">
                    <Column size="3" className="pl-0">
                        <Field>
                            <Control>
                                <StyledSearchBar
                                    placeholder="Search by Name, Id and Phone No"
                                    onChange={handleSearch}
                                />
                                <Icon name="search" position="right" />
                            </Control>
                        </Field>
                    </Column>
                    <Column className="size-3">
                        {matchState &&
                            <div className="is-flex is-align-items-center"> <CgSearchFound color="gray" size={20} className="mr-2" /> <p className="has-text-black">
                                {matchState === "id"
                                    ? <SearchingBy>Searched by Business ID</SearchingBy> : matchState === "name"
                                        ? <SearchingBy>Searched by Business Name</SearchingBy> : matchState === "smsPhone"
                                            ? <SearchingBy>Searched by Business Number</SearchingBy> : matchState === "whatsAppPhone"
                                                ? <SearchingBy>Searched by Business Number</SearchingBy> : ""} </p>
                            </div>
                        }

                    </Column>
                </Columns>
                <div className="is-hidden-mobile">
                    {isEmptyBusinesses ? (
                        <NoSearchFound />
                    ) : (
                        <DeleteTableView
                            refetch={refetch}
                            locations={businessState}
                            setLocations={setBusinessState}
                            businessStateInitial={businessStateInitial}
                            setBusinessStateInitial={setBusinessStateInitial}
                            setIsRefetching={setIsRefetching} />
                    )}
                </div>
            </Container>
        </Section>
    );
}

export default ManageBusiness;

const SearchingBy = styled.p`
  font-size: 16px;
  color: gray;
  margin: 10px 0;
`;
